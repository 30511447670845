export const SET_LANG = "SET_LANG";

export const setLanguage = (Data) =>{
    return{
        type: SET_LANG,
        payload: Data
    }
}



